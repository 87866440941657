import { FileDownloadOutlined, Logout } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  type DefaultOverlayEventPayload,
  type DefaultOverlayProps,
} from "@pimo/pimo-components";
import { Profile } from "rr-types";

export type RROverlayProps = DefaultOverlayProps & {
  reportingDate?: string;
  userProfile?: Profile;
};

export type RROverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:download-report"
  | "overlay:download-ppt";

type RROverlayEventPayload =
  | { reportingDate: string }
  | { pptType: "dashboard" | "regions" | "reports"; id: string | undefined }
  | DefaultOverlayEventPayload;

export const RROverlay: PimoReactComponent<
  RROverlayProps,
  RROverlayEventNames,
  RROverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const urlParameters = window.location.pathname.split("/");

  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() =>
            (location.href = "mailto:isrm@allianz.com?subject=RISK REPORTING:")
          }
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Contact Us
        </Button>
      </Box>
    ),
    entries: [
      {
        icon: FileDownloadOutlined,
        onClick: () => fireEvent?.("overlay:download-report"),
        text: "Download PDF Report",
      },
      ...(props.userProfile?.isAdmin
        ? [
            {
              icon: FileDownloadOutlined,
              onClick: () =>
                fireEvent?.("overlay:download-ppt", {
                  pptType: "dashboard",
                  id: undefined,
                }),
              text: "Download Group ICT PPT Report",
            },
          ]
        : []),
      ...(window.location.pathname.includes("regions") ||
      window.location.pathname.includes("reports")
        ? [
            {
              icon: FileDownloadOutlined,
              text: `Download ${
                urlParameters[1] === "regions" ? "Region" : "OE"
              } ICT PPT Report`,
              onClick: () =>
                fireEvent?.("overlay:download-ppt", {
                  pptType: urlParameters[1] as "regions" | "reports",
                  id: urlParameters[2],
                }),
            },
          ]
        : []),

      {
        icon: Logout,
        onClick: () => fireEvent?.("overlay:logout"),
        text: "Logout",
      },
    ],
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
