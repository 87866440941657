/* eslint-disable @typescript-eslint/no-explicit-any */
import PptxGenJS from "pptxgenjs";

import { fetchDashboard } from "./fetch-dashboard";
import { fetchOE } from "./fetch-oe";
import { fetchRegion } from "./fetch-region";
export const generateRegionPPT = async (pptx: PptxGenJS, id: string) => {
  const regionData = await fetchRegion(Number(id));

  // Add slides to the presentation
  const slide = pptx.addSlide();

  const tableData = [
    [
      {
        text: "KPI",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
      {
        text: "Value",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
    ],
  ];

  // KPI values
  const kpiValues = [
    [
      "Number of accepted high and very high IS risks without mitigation plan, relative to last period (quarter)",
      regionData.region.quarterComparison
        ?.ISAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks assigned to the incorrect owner, relative to last period (quarter)",
      regionData.region.quarterComparison
        ?.ISIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks delayed in management process, relative to last period (quarter)",
      regionData.region.quarterComparison?.ISDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of accepted high and very high ISMS risks without mitigation plan, relative to last period (quarter)",
      regionData.region.quarterComparison
        ?.ISMSAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks assigned to the incorrect owner, relative to last period (quarter)",
      regionData.region.quarterComparison
        ?.ISMSIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks delayed in management process, relative to last period (quarter)",
      regionData.region.quarterComparison
        ?.ISMSDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified high and very high IS risks, relative to total number of IS risks",
      regionData.region.quarterComparison
        ?.ISHighAndVeryHighOutOfTotalNumberOfRisks,
    ],
    [
      "Number of overdue mitigation plans, relative to total number of mitigation plans",
      regionData.region.quarterComparison
        ?.numberOfOverdueMitigationPlansRelativeToTotalNumberOfMitigationPlans,
    ],
    [
      "Number of identified high and very high ISMS risks",
      regionData.region.quarterComparison?.ISMSHighAndVeryHighNumberOfRisks,
    ],
    [
      "Number of high and very high IS Risks with overdue mitigation plans",
      regionData.region.quarterComparison
        ?.ISNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
    [
      "Number of high and very high ISMS Risks with overdue mitigation plans",
      regionData.region.quarterComparison
        ?.ISMSNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
  ];

  kpiValues.forEach(([kpi, value]) => {
    const color = value.includes("-")
      ? "#71ad47"
      : value.includes("+")
        ? "#c00000"
        : "000000"; // Green for negative, Red for positive, Black for neutral
    tableData.push([
      { text: kpi, options: { fontSize: 12 } as any },
      {
        text: value,
        options: { fontSize: 12, color: color, bold: true },
      },
    ]);
  });
  slide.addText(regionData.region.regionName, {
    x: 0.5,
    y: 0.2,
    fontSize: 12,
    bold: true,
  });
  // Add table to slide
  slide.addTable(tableData as any, {
    x: 0.5,
    y: 0.5,
    w: 9,
    colW: [7, 2],
    fontSize: 14,
    border: { type: "solid", color: "000000", pt: 1 },
  });
};
export const generateDashboardPPT = async (pptx: PptxGenJS) => {
  const dashboardData = await fetchDashboard();
  const quarterComparison = dashboardData?.dashboardData?.quarterComparison;
  const slide = pptx.addSlide();

  const tableData = [
    [
      {
        text: "KPI",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
      {
        text: "Value",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
    ],
  ];

  // KPI values
  const kpiValues = [
    [
      "Number of accepted high and very high IS risks without mitigation plan, relative to last period (quarter)",
      quarterComparison?.ISAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks assigned to the incorrect owner, relative to last period (quarter)",
      quarterComparison?.ISIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks delayed in management process, relative to last period (quarter)",
      quarterComparison?.ISDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of accepted high and very high ISMS risks without mitigation plan, relative to last period (quarter)",
      quarterComparison?.ISMSAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks assigned to the incorrect owner, relative to last period (quarter)",
      quarterComparison?.ISMSIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks delayed in management process, relative to last period (quarter)",
      quarterComparison?.ISMSDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified high and very high IS risks, relative to total number of IS risks",
      quarterComparison?.ISHighAndVeryHighOutOfTotalNumberOfRisks,
    ],
    [
      "Number of overdue mitigation plans, relative to total number of mitigation plans",
      quarterComparison?.numberOfOverdueMitigationPlansRelativeToTotalNumberOfMitigationPlans,
    ],
    [
      "Number of identified high and very high ISMS risks",
      quarterComparison?.ISMSHighAndVeryHighNumberOfRisks,
    ],
    [
      "Number of high and very high IS Risks with overdue mitigation plans",
      quarterComparison?.ISNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
    [
      "Number of high and very high ISMS Risks with overdue mitigation plans",
      quarterComparison?.ISMSNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
  ];

  kpiValues.forEach(([kpi, value]) => {
    const color = value?.includes("-")
      ? "#71ad47"
      : value?.includes("+")
        ? "#c00000"
        : "000000"; // Green for negative, Red for positive, Black for neutral
    tableData.push([
      { text: kpi, options: { fontSize: 12 } as any },
      { text: value, options: { fontSize: 12, color: color, bold: true } },
    ]);
  });
  slide.addText("Group", {
    x: 0.5,
    y: 0.2,
    fontSize: 12,
    bold: true,
  });
  // Add table to slide
  slide.addTable(tableData as any, {
    x: 0.5,
    y: 0.5,
    w: 9,
    colW: [7, 2],
    fontSize: 14,
    border: { type: "solid", color: "000000", pt: 1 },
  });
};

export const generateOePPT = async (pptx: PptxGenJS, id: string) => {
  const oeData = await fetchOE({ id: id });

  // Add slides to the presentation
  const slide = pptx.addSlide();

  const tableData = [
    [
      {
        text: "KPI",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
      {
        text: "Value",
        options: { bold: true, color: "ffffff", fill: "#023781" },
      },
    ],
  ];

  // KPI values
  const kpiValues = [
    [
      "Number of accepted high and very high IS risks without mitigation plan, relative to last period (quarter)",
      oeData.quarterComparison?.ISAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks assigned to the incorrect owner, relative to last period (quarter)",
      oeData.quarterComparison?.ISIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified IS risks delayed in management process, relative to last period (quarter)",
      oeData.quarterComparison?.ISDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of accepted high and very high ISMS risks without mitigation plan, relative to last period (quarter)",
      oeData.quarterComparison
        ?.ISMSAcceptedHighAndVeryHighRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks assigned to the incorrect owner, relative to last period (quarter)",
      oeData.quarterComparison?.ISMSIncorrectRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified ISMS risks delayed in management process, relative to last period (quarter)",
      oeData.quarterComparison?.ISMSDelayedRisksRelativeToLastQuarter,
    ],
    [
      "Number of identified high and very high IS risks, relative to total number of IS risks",
      oeData.quarterComparison?.ISHighAndVeryHighOutOfTotalNumberOfRisks,
    ],
    [
      "Number of overdue mitigation plans, relative to total number of mitigation plans",
      oeData.quarterComparison
        ?.numberOfOverdueMitigationPlansRelativeToTotalNumberOfMitigationPlans,
    ],
    [
      "Number of identified high and very high ISMS risks",
      oeData.quarterComparison?.ISMSHighAndVeryHighNumberOfRisks,
    ],
    [
      "Number of high and very high IS Risks with overdue mitigation plans",
      oeData.quarterComparison
        ?.ISNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
    [
      "Number of high and very high ISMS Risks with overdue mitigation plans",
      oeData.quarterComparison
        ?.ISMSNumberOfHighAndVeryHighRiskWithOverdueMitigationPlans,
    ],
  ];

  kpiValues.forEach(([kpi, value]) => {
    const color = value.includes("-")
      ? "#71ad47"
      : value.includes("+")
        ? "#c00000"
        : "000000"; // Green for negative, Red for positive, Black for neutral
    tableData.push([
      { text: kpi, options: { fontSize: 12 } as any },
      {
        text: value,
        options: { fontSize: 12, color: color, bold: true },
      },
    ]);
  });
  slide.addText(oeData.oeName, {
    x: 0.5,
    y: 0.2,
    fontSize: 12,
    bold: true,
  });
  // Add table to slide
  slide.addTable(tableData as any, {
    x: 0.5,
    y: 0.5,
    w: 9,
    colW: [7, 2],
    fontSize: 14,
    border: { type: "solid", color: "000000", pt: 1 },
  });
};
